.requests-detiles-info{
    display: flex;
    // align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 25px;
    @media (max-width : 900px) {
            flex-direction: column !important;
            .requests-detiles-info-client{
                width: 100% !important;
                text-align: center !important;
            }
    }
    .requests-detiles-info-card{
        background: #fff;
        padding: 37px 20px 20px 44px;
        border-radius: 16px;
        width: 100%;
        .requests-detiles-info-card-title{
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                color: #484C52;
                padding: 0 0 25px 0;
                border-bottom: 1px solid#8F9BB333;
                display: flex;
                align-items: center;
                gap: 5px;
                margin: 0;
        }
        .requests-detiles-info-card-data{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 5px;
            .requests-detiles-info-card-data-item{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                .data-item-title{
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #8FA0B4;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    gap: 3px;
                    .data-item-icon{
                        color: #6D29F6;
                        font-weight: 16px;
                    }
                }
                .data-item-text{
                    color: #484C52;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 20px;
                }
                .data-item-type{
                    padding: 7px 12px 8px 12px;
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 12px;
                }
            }
        }
    }
    .requests-detiles-info-client{
        width: 270px;
        padding: 37px 20px 20px 44px;
        border-radius: 16px;
        background: #fff;
        .requests-detiles-info-client-title{
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            color: #484C52;
            padding: 0 0 25px 0;
            border-bottom: 1px solid #8F9BB333;
            margin: 0;
        }
        .requests-detiles-info-client-data{
            display: flex;
            align-items: center;
            justify-content: start;
            flex-direction: column;
            gap: 15px;
            margin-top: 20px;
        }
        .requests-detiles-info-client-data-item{
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 10px;
            .data-item-title{
                color: #484C52;
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                margin: 0;
            }
            .data-item-text{
                color: #8F9BB3;
                font-size: 14px;
                font-weight: 400;
                line-height: 23px;
                margin: 0;
            }
        }
    }
}