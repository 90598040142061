.terms {
  direction: rtl;
  padding: 30px 0;
  .containter {
    max-width: 80%;
    margin: auto;
    @media screen and (max-width: 500px) {
      max-width: 100%;
      padding: 0 16px;
    }
  }
  .title-terms {
    cursor: pointer;
    color: #6d29f6;
    font-size: 30px;
    font-weight: 700;
    line-height: 48px;
  }
  .container-terms {
    width: 100%;
    height: 85vh;
    overflow: auto;
    // border: 1px solid #8f9bb333;
    background: #fff;
    .text-terms {
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      color: #484c52;
      margin-bottom: 10px;
      text-align: justify;
      padding: 30px 20px;
      border-radius: 8px;
      p {
        width: 88%;
        margin: 0;
        padding: 0;
        font-size: 16px;
        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }
    }
  }
}
