.cards{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(340px,1fr));
    gap: 30px;
    margin-top: 20px;
}
@media (max-width: 450px ) {
    .cards{
        // grid-template-columns: 1fr;
        grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
    }
}
.card{
    border-radius: 24px;
    background: #fff;
    border-radius: 24px;
    .ant-card{
        width: 100% !important;
        height: 100% !important;
    }
    .ant-card-body{
        padding: 0 20px 25px !important;
    }
    // width: 406px;
    .ant-card-cover{
        padding: 10px;
        .card-img{
            display: flex;
            height: 280px;
            width: 100%;
            justify-content: center;
            align-items: center;
            background: #F5F6F7;
            border-radius: 20px;
            text-align: center;
            overflow: hidden;
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
                flex-shrink: 0;
            }
        }
    }
    .card-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        .card-header-title{
            font: {
                size: 20px;
                weight:700;
            };
            line-height: 32px;
            letter-spacing: 0.5px;
            color:#484C52;
        }
        .card-price{
            color: #6D29F6;
            font: {
                size: 20px;
                weight:700;
            };
            line-height: 32px;
            letter-spacing: 0.5px;
        }
        border-bottom: 1px solid #8F9BB3 ;
    }
    .card-info-line,
    .card-info{
        display: flex;
        align-items: center;
        gap: 10px;
        .card-info-title{
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.5px;
            color: #484C52;
        }
        .card-info-desc{
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.5px;
            color: #8F9BB3;
        }
    }
    .card-info-line{
        flex-direction: column;
        align-items: start;
        gap: 0;
    }
    .category{
        .card-info-title{
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.5px;
            color: #484C52;
        }
        .category-btns{
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 6px;
            flex-wrap: wrap;
            .category-btn{
                padding: 7px 12px 8px 12px;
                // padding: 7px 8px;
                background: #F5F6F7;
                border-radius: 4px;
                border: none;
                cursor: pointer;
            }
        }
    }
}

.no-products{
    width: 100%;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .no-products-title{
        margin-top: 0 !important;
    }
}