.dashboard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    .dashboard-title{
        .dashboard-title-text{
            font-size: 30px;
            font-weight: 700;
            color: #6D29F6;
        }
    }
    .dashboard-card{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        flex-wrap: wrap;
        cursor: pointer;
        .ant-card-body{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            // // padding: 12px 12px 23px 12px;
            padding: 12px;
            height: 100%;
            width: 100%;
            gap: 9px;
        }
        .dashboard-card-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            justify-content: start;
            width: 100%;
            .card-title-dashboard-container{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 6px;
                .card-title-dashboard{
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                }
                .card-icon-dashboard{
                    width: 16px;
                    height: 16px;
                    margin: 0;
                }
            }
            .card-content{
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 30px 30px;
                height: 50px;
                .card-content-dashboard{
                    margin: 0;
                    color: var(--primary-dark-grey, #484C52);
                }
            }
        }
    }
}