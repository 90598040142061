.ant-input-wrapper{
    width: 100%;
    height: 55px;
    .ant-input-search-button,
    .ant-input{
        width: 100%;
        height: 100%;
    }
}
@media (max-width : 900px) {
    .client-list{
        width: 100% !important;
    }
}