.slider-page{
    position: relative;
    margin-top: 40px;
    .ant-pagination{
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        border-top: 1px solid rgb(0 37 84 / 10%); 
    }
}