
.category-add-btn-bold{
    color: #6D29F6;
    font-size: 25px;
    font-weight: 700;
    line-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.category-add-btn{
    color: #6D29F6;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.products-edit-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .category-add-btns{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-btn-text.ant-btn-dangerous{
        font-size: 17px;
        font-weight: 600;
    }
}
@media (max-width: 500px) {
    .add-event{
        justify-content: center ;
        align-items: center ;
        .category-add-btn-bold{
            font-size: 20px;
            line-height: normal;
        }
        .category-add-btn{
            font-size: 18px;
            line-height: normal;
        }
    }
}


.ant-modal-title{
    color: var(--primary-text, #6D29F6) !important ;
    font-size: 20px !important;
    font-weight: 700 !important ;
}
.ant-modal-close-icon{
    color: #484C52 !important ;
    width: 26px;
    height: 25px;
    justify-content: center;
    align-items: center;
}
.ant-form-item-required{
    color: var(--primary-dark-grey, #484C52);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
}