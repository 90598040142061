
.card-image-edit-modal-container{
    @media (max-width: 992px) {
        .ant-modal-content{
            width: auto !important;
        }
        .card-image-edit-modal{
            width: 100% !important;
        }
    }
    .ant-form-item{
        margin-bottom: 0 !important;
    }
.ant-modal-content{
    // display: flex;
    width: 692px;
    padding: 24px 36px;
    // justify-content: flex-end;
    // align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
.ant-modal-body{
    .card-image-edit-modal{
        width: 601px;
        .ant-upload-list-picture{
            margin-bottom: 20px;
        }
        .ant-upload-drag{
            background: #fff !important;
            border-color: #E5E7EB !important ;
            padding:  48px;
            border-radius:  12px;
            margin: 22px 0;
        }
        .card-edit-modal{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap:  20px;
            // padding:  48px;
            // border-radius:  12px;
            // border: 1px dashed  #E5E7EB;
            // background:  #FFF;
            // margin: 22px 0;
            .edit-modal-img{
                width: 70px;
                height: 44.754px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .edit-modal-title{
                h3{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 5px;
                    color:#1F2937;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.08px;
                    margin: 0;
                }
                span{
                    color: #6D29F6;
                    font-size: 17px;
                    font-weight: 500;
                    letter-spacing: 0.08px;
                    cursor: pointer;
                }
            }
            .edit-modal-text{
                p{
                    color:  #9CA3AF;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.07px;
                    margin: 0;
                }
            }
        }
    }
    .ant-upload-list-picture{
        // margin-bottom: 15px;
    }
    .card-edit-modal-btns{
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 22px;
        .card-edit-modal-btn-cancel{
            color:#8F9BB3;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
        }
        .card-edit-modal-btn{
            
        }
    }
    }
    .ant-upload-picture-card-wrapper{
        width: auto !important;
    }
    .ant-upload-select {
    width: 100%  !important;
    height: 100% !important;
    margin-inline-end: 0 !important;
    margin-bottom: 0 !important;
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
    }
  }
}