.product-add {
  .product-add-content {
    display: flex;
    justify-content: center;
    gap: 20px;
    @media (max-width : 900px) {
      flex-direction: column;
      .ant-upload-select,
      .add-images{
        width: 100% !important;
      }
    }
    @media  (max-width : 500px) {
      .add-info-form{
        .add-title{
          font-size: 22px !important;
        }
      }
      .add-info-category{
        .category-add-header{
          flex-direction: column;
          justify-content: center;
          margin-bottom: 15px;
        }
      }
    }
    position: relative;
    .add-info {
      width: 100%;
    }
    .ant-upload-list-item-container{
      height: auto !important;
    }
    .add-images {
      width: 431px;
      .ant-upload-select{
        padding: 117.5px 125px 116.5px 126px;
        height: 300px;
        background: #fff;
        border-radius: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-upload-list{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap-reverse;
        // gap: 10px;
        .ant-upload-list-item-container{
          width: fit-content;
        }
        .ant-upload-list-item{
          border: none !important;
          width: 100px;
          height: 95px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 15px 13px 15px;
          border-radius: 6px;
          background:  #FFF;
          a{
            width: 80px;
            height: 70px;
            border-radius: 10px;
            cursor: pointer;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .ant-upload-list-item-actions,
          .ant-upload-list-item-name{
            // display: none !important;
          }
        }
      }
      .image-upload-list{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          flex-wrap: wrap;
          .image-upload{
            width: 100px;
            height: 95px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 15px 13px 15px;
            border-radius: 6px;
            background:  #FFF;
            img{
              width: 80px;
              height: 70px;
              object-fit: cover;
              border-radius: 10px;
              cursor: pointer;
              transition: 0.3s;
            }
          }
      }
      .ant-upload {
        border: none;
        cursor: pointer;
      }
      // .anticon 
      .upload-btn-wrapper-edit
      {
        background: #f5f6f7;
        color: #6d29f6;
        font-size: 50px;
        padding: 5px;
        border-radius: 14px;
      }
    }
    .upload-btn {
      margin-top: 10px;
      color: #6d29f6;
      font-size: 20px;
      font-weight: 600;
    }
    .add-label {
      span{
        color:  #8F9BB3;
        font-size: 14px;
        font-weight: 500;
      }
      color: #484C52;
      text-align: right;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.5px
    }
    .add-info-form {
      background: #fff;
      padding: 5px 20px;
      border-radius: 10px;
      .ant-input-affix-wrapper{
        width: 95%;
        .ant-input-suffix{
          position: absolute;
          left: -36px;
          color: var(--primary-dark-grey, #484C52);
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
      }
      .ant-input-number-suffix{
        position: absolute;
        left: -46px;
        color: var(--primary-dark-grey, #484C52);
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .ant-input-number-handler-wrap{
        display: none;
      }
      .add-title {
        color: #6d29f6;
        font-size: 25px;
        font-weight: 700;
        line-height: 40px;
      }
    }
    .add-info-category{
      background: #fff;
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 24px 36px;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 12px;
      gap: 10px;
      margin: 24px 0;
      .header-product{
        width: 100%;
        margin-bottom: 22px;
        padding: 0 0 22px;
        border-bottom: 1px solid #8F9BB333;
      }
      .closeItem{
        position: absolute;
        top: 1px;
        right: 1px;
        font-size: 12px;
        padding: 8px 10px;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0.8;
    &:hover {
        opacity: 1;
        transform: scale(1.2);
    }
  }
      .category-check{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 22px;
        flex-direction: column;
          .radio-btn-group{
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 12px;
            width: 100%;
            .radio-btn{
              display: flex;
              height: 35px;
              padding: 7px 12px 8px 12px !important;
              justify-content: center;
              align-items: center;
              border-radius: 4px !important;
              color: #484C52 !important;
              font-size: 14px !important;
              font-weight: 700 !important;
              border: none !important;
              background:  #F5F6F7 !important;
              &::before{
                display: none;
              }
              .ant-radio-button-checked{
                color: #6d29f6 !important;
                background: rgba(109, 41, 246, 0.10) !important;
              }
            }
          }
        }
        .ant-form-item-label >label{
          color: #484C52;
          text-align: right;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0.5px;
          width: 100%; 
        }
      }
    }
  }



