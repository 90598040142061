@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap");
:root {
  --color-primary: #0e18ab;
  --color-display: #00b2f3;
  --color-secondary: #005a7b;
  --color-bg: #f5fcff;
  --color-bg-2: #ebfaff;
  --color-gray: #828385;
  --color-gray-light: #f6f6f6;
  --color-success: #4bb543;
  --color-warn: #faad14;
  --color-danger: #ff4d4f;
  --font-primary: Cairo, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Cairo", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  --swiper-theme-color: #001a62 !important;
}
// *{
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./antd";
@import "./form";
@import "./typography";

@layer base {
  html {
    @apply scroll-smooth;
  }
  body {
    min-height: 100vh;
    background-color: #eeeeee;
  }
}
.ant-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 0 2rem 0 rgba(33, 37, 41, 0.1);
  padding: 16px;
}
.ant-table-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  // color: #fff;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.auth-box-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .auth-box-holder {
    box-shadow: 0 6px 36px rgba(0, 0, 0, 0.08);
    padding: 30px 40px;
    background-color: #fff;
    border-radius: 16px;
    max-width: 520px;
    min-width: 500px;

    .ant-form-item .ant-form-item-label{
      text-align: end;
    }
    .account-register-row {
      width: 100%;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      color: #838383;
      margin: 20px auto 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      a {
        text-decoration: underline;
      }
    }
    @media screen and (max-width: 500px) {
      width: 90%;
      min-width: auto;
      padding: 30px;
    }
  }
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #d9d9d9;
}

.table-summary-action {
  padding: 12px;
}

.ant-btn.ant-btn-danger {
  color: #cf2110;
}

.ant-input[disabled] {
  color: #000;
}

.steps-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .step-content-holder {
    // max-width: 520px;
    min-width: 650px;
    background-color: #fff;
    padding: 30px 40px;
    box-shadow: 0 6px 36px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
  }
}

.upload-img-holder {
  .ant-form-item-control-input-content {
    border: 1px #d9d9d9 dashed;
    max-width: 240px;
    padding: 16px;
    border-radius: 6px;
    margin: auto;
  }
}

.ant-btn-primary {
  width: fit-content;
  background: #6d29f6;
  color: #fff;
  padding: 20px 40px;
  border-radius: 25px;
  font-size: 20px;
  font-weight: 600;
}
.ant-skeleton-element{
  width: 100% !important;
}
.product-skeleton{
  .ant-skeleton-input,
  .ant-skeleton.ant-skeleton-element{
      width: 100% !important;
  }
  .image-upload{
      width: auto !important;
      overflow: hidden;
      background: none !important;
  }
}

.ant-skeleton-image{
  width: 100% !important;
  height: 200px !important;
}
.btn-edit-delete{
  &:hover{
      background: none !important;
  }
}

@media  (max-width: 550px ) {
  .btn-delete{
    display: none !important;
  }
}
main{
  margin-bottom: 100px !important;
} 