.add-event{
    .add-info{
        display: flex;
        // width: 80%;
        flex-direction: column;
        // align-items: center;
        gap: 22px;
        color: #404D61;
        padding: 30px;
        border-top: 1px solid #8F9BB333;
    }
}
.add-event-form{
    // .ant-btn-default,
    .ant-upload-select,
    .ant-upload-select{
        width: 100% !important;
    }
    .emoji-upload-btn{
        span{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: start;
            // color: rgb(72 76 82 / 29%);
            font-size: 16px;
            font-weight: 500;
        }
        box-shadow: none !important;
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        background-color: #ffffff;
    }
}