.image-page-container{
    .image-page-cards{
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(340px,1fr));
        gap: 30px;
        margin-top: 20px;
    }
    @media (max-width: 450px ) {
        .image-page-cards{
            grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
        }
    }
}