.ant-menu-item-selected{
    .navbar-icon{
        background: #6D29F6;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        color: #fff !important;
        padding: 5px;
    }
    background: none !important;
    color: #6D29F6;
    font-size: 17px;
    font-weight: 700;
    line-height: 14px;
}
// .ant-layout-sider-has-trigger{
//     .brand-logo{
//         width: 36px;
//         height: 30px;
//     }
// }
.brand-logo{
    width: 80%;
    border-bottom: 1px solid #C9C7C7 ;
    margin: 1rem auto;
    img{
        width: 56px;
        height: 47px;
    //     width: 100px;
    //     height: 100px;
        object-fit: contain;
    }
}
.ant-avatar-string{
    position: relative !important;
    left: 0 !important;
}

.badge-style{
    .ant-badge-count{
        background: #EF5457 !important;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        text-align: center;
        right: -8px;
        top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: normal;
        font-size: 12px;
    }
}
.badge-style-own{
    .ant-badge-dot{
        width: 10px;
        height: 10px;
        background: #40C1AC !important;
        border-radius: 50%;
        right: -2px;
        top: 7px;
        box-shadow: none;
    }
}
.notification-card{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    .notification-card-info{
        display: flex;
        align-items: end;
        justify-content: center;
        flex-direction: column;
        .notification-card-info-title{
            color: #000;
            font-size: 15px;
            font-weight: 600;
            span{
                color: #444;
            }
            margin: 0;
        }
        .notification-card-info-time{
            color: gray;
            font-size: 12px;
            margin: 0;
        }
    }
    img{
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
}
.dropdown-style-own{
    max-height: 320px;
    overflow-y: auto;
    .ant-dropdown-menu{
        padding: 0 !important;
    }
    .ant-dropdown-menu-item-group-title{
        color: #000 !important;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        border-bottom: 1px solid #8b889074;
        width: 100%;
        padding: 12px;
        margin-bottom: 10px;
    }
    .ant-dropdown-menu-item-group-list{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        margin: 0;
        .ant-dropdown-menu-item{
            border: 0;
        }
    }
    .ant-dropdown-menu-item-only-child{
        border-bottom: 1px solid #8b889074;
    }
     .view-all{
        text-align: center;
        background: #008000;
        color: #fff !important;
        position: sticky !important;
        bottom: 0;
        left: 0;
        width: 100%;
        &:hover{
            background: #008000 !important;
        }
    }
    .ant-dropdown-menu{
        padding: 0 !important;
    }
}