.card-image-container{
    .card-image-items{
        background: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px ;
        border-radius: 24px;
        gap: 24px;
        .ant-card-cover{
            padding: 10px;
            width: 100%;
            .card-img{
                display: flex;
                height: 280px;
                width: 100%;
                justify-content: center;
                align-items: center;
                background: #F5F6F7;
                border-radius: 20px;
                text-align: center;
                overflow: hidden;
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .ant-card{
            width: 100% !important;
            height: 100% !important;
        }
        .ant-card-body{
            padding: 0 20px !important;
            width: 100%;
        }
        .card-image-content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .card-image-utils{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 7px;
                .card-image-delete{
                    font-size: 24px;
                    color: #B91D43;
                    cursor: pointer;
                }
                .card-image-edit{
                    font-size: 24px;
                    color: #484C52;
                    cursor: pointer;
                }
            }
            .card-image-number{
                p{
                    color: #1B153D;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
    }
}
.ant-modal-close{
    top: auto !important;
}