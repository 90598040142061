.events-list{
    margin-top: 40px ;
    .table{
        padding: 0px 24px;
    }
    .ant-table-thead > tr> th {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px; 
        letter-spacing: 0.42px;
        height: 76px;
    }
    .emoji-img{
        width: 36px;
        height: 36px;
        &::after{
            content: attr(data-emoji);
        }
    }
    // .ant-table-cell-execution{
    //     display: flex;
    //     justify-content: flex-end;
    //     align-items: center;
    //     width: 150px;
    //     height: 76px;
    //     left: 0;
    // }
    // .ant-table-cell-execution{
    //     height: 76px;
    //     display: flex;
    //     justify-content: flex-end;
    //     align-items: center;
    //     left: 0;
    //     width: 150px;
    // }
}