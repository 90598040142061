.delivery-details {
  margin-top: 40px;
  .delivery-details-title {
    font-size: 30px;
    font-weight: 700;
    color: #6d29f6;
    border-bottom: 1px solid #8f9bb333;
    padding-bottom: 20px;
  }
  .delivery-info {
    display: flex;
    align-items: center;
    gap: 10px;
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #484c52;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: #8f9bb3;
    }
  }
}
