
.card-image-delete-modal-container{
    .ant-modal-body{
    .card-image-delete-modal{
        // display: flex;
        // width: 414px;
        // height: 283px;
        // padding: 24px 24px 19px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        .card-image-delete-modal-title{
            color: #002554;
            font-size: 25px;
            font-weight: 700;
            margin: 30px 0 0;
        }
        .card-image-delete-modal-text{
            color:  #002554;
            font-size: 18px;
            font-weight: 400;
            opacity: 0.7;
            margin: 10px 0 30px;
        }
        .card-image-delete-modal-btns{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            flex-direction: column;
            .card-image-delete-modal-btn{
                display: flex;
                height: 44px;
                width: 100%;
                padding: 0px 16px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 105px;
                background: #EB5959;
                color: #fff;
                box-shadow: 0px 7px 10px 0px rgba(41, 38, 50, 0.03);
                
            }
            .card-image-delete-modal-btn-cancel{
                color: #002554;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
    }
}