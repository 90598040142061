.ant-picker-body{
    display: none;
}
.ant-picker-calendar{
    background: none;
    .ant-select-selector{
        border: none !important;
        &:focus{
            box-shadow: none !important;
            border: none !important;
        }
    }
    .ant-select-in-form-item {
        display: flex;
        width: 105px !important ;
        height: 47px !important;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--grey-grey-3, #E1E3E6) !important ;
        background: var(--White, #FFF);
    }
}