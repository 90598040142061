.color-blu{
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #005EB8;
}
.highlight {
    padding: 7px 12px 8px 12px;
    border-radius: 16px;
    background: #755DA933;
    color: #6D29F6;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
// .ant-table-tbody >tr >td {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
.ant-table-thead{
    .ant-table-cell{
        &::before{
            display: none;
        }
        background:#fff !important;
        padding: 0px 24px 0px 24px;
        color: #484C52 !important;
    }
}
.ant-table-thead >tr>th,
.ant-table-tbody >tr >td{
    text-align: center !important;
}
.ant-table-cell-row-hover{
    background: #F9FBFD !important;
}
.request-search{
    width: 100%;
    height: 55px;
    border-radius: 5px;
    margin-bottom: 24px;
    &::placeholder{
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: #8FA0B4;
        text-transform: capitalize;
    }
}
.ant-pagination-item-active{
    width: 32px !important;
    height: 29px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    background: #6D29F6 !important;
    padding: 4px 8px !important;
    border-radius: 4px !important;
    a{
        color: #fff !important;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
    }
}
.ant-table-tbody{
    .ant-table-row-level-0{
        cursor: pointer;
    }
}
.client-list,
.requests{
    margin-top: 40px;
    .ant-input-wrapper{
        direction: ltr;
        width: 755px;
        .ant-input{
            direction: ltr;
            text-align: right;
            border-right: none;
            &:focus,
            &:hover{
                box-shadow: none;
                border-color: #d9d9d9 ;
                // border: none;
            }
        }
        .ant-input-group-addon{
            border-radius: none;
            border: none;
            color: #8FA0B4;
            .ant-btn{
                border-radius: none;
                border-left: none;
                &:hover,
                &:active{
                    border-color: #d9d9d9 !important ;
                }
                .anticon{
                    width: 24px;
                    height: 24px;
                    color: #8FA0B4;
                    font-size: 20px;
                    line-height: 20px;
                    padding: 0;
                    margin: 0;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
.ant-pagination-disabled{
    span{
        color: #90969E;
    }
}
.ant-table-pagination.ant-pagination{
    margin: 0 !important;
    display: flex;
    align-items: center;
    gap: 5px;
    // border-top: 1px solid #d9d9d9;
    border-top: 1px solid rgb(0 37 84 / 10%); 
    
}
.ant-pagination-total-text{
    position: absolute;
    right: 0;
    color:  #484C52;
    font-size: 12px;
    font-weight: 600;
}
@media  (max-width: 776px ) {
    .ant-pagination-total-text{
        display: none !important;
    }
    // .ant-pagination-item-active{
    //     width: 25px !important;
    //     height: 25px !important;
    // }
}
.ant-table{
    margin-bottom: 40px !important;
}
@media  (max-width: 1200px) {
    .ant-input-wrapper{
        width: 100% !important;
    }
}