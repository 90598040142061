.restaurant-details,
.product-details{
    .ant-skeleton-image,
    .ant-skeleton-element{
        width: 100% !important;
        height: 100% !important;
    }
    .product-details-card{
        display: flex;
        justify-content: center;
        gap: 24px;
        .product-details-imgs{
            width: 430px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            .active{
                width: 431px;
                height: 370px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 12px; 
                text-align: center;
                img{
                    max-width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    object-fit: cover;
                    cursor: pointer;
                }
            }
            .details-img{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 12px;
                width: 100%;
                .img-container{
                    background: #fff;
                    border-radius: 8px;
                    width: 110px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .image-upload{
                    width: 100px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px;
                    border-radius: 6px;
                    background:  #FFF;
                    transition: 0.3s;
                    &:hover{
                        transform: translateY(-5px);
                    }
                    img{
                        width: 80px;
                        height: 70px;
                        object-fit: cover;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: 0.3s;
                    }
                  }
            }
        }
        .product-details{
            width: 100%;
            background: #fff;
            padding: 20px 50px;
            border-radius: 20px;
            display: flex;
            padding: 40px;
            flex-direction: column;
            gap: 24px;
            .details-header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                // padding: 10px 5px;
                .details-header-title{
                    font: {
                        size: 20px;
                        weight:700;
                    };
                    line-height: 32px;
                    letter-spacing: 0.5px;
                    color:#484C52;
                    margin: 0 !important;
                }
                .details-price{
                    color: #6D29F6;
                    font: {
                        size: 20px;
                        weight:700;
                    };
                    line-height: 32px;
                    letter-spacing: 0.5px;
                }
            }
            .details-info{
                border-top: 1px solid #8F9BB3 ;
                padding-top: 24px;
                .card-info-line,
                .card-info{
                display: flex;
                align-items: center;
                gap: 10px;
                .card-info-title{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #484C52;
                }
                .card-info-desc{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #8F9BB3;
                }
                }
                .card-info-line{
        flex-direction: column;
        align-items: start;
        gap: 0;
                }
                .category{
        .card-info-title{
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.5px;
            color: #484C52;
        }
        .category-btns{
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 6px;
            flex-wrap: wrap;
            .category-btn{
                padding: 7px 12px 8px 12px;
                background: #F5F6F7;
                border-radius: 4px;
                border: none;
                cursor: pointer;
            }
            }
                }
            }
        }
    }
}
.img-active{transform: translateY(-5px);}



@media (max-width : 900px) {
.flowers-details-card,
.product-details-card{
    // grid-template-columns: 1fr !important;
    flex-direction: column;
    .product-details-imgs{
        width: 100% !important;
        .active{
            width: 100% !important;
        } 
        .details-img{
            gap: 20px !important;
        }
        
    }
}
}







