.ant-picker {
  width: 100%;
}

// .ant-form .ant-form-item {
//   margin-bottom: 0px;
// }

.checkbox-list-wrapper {
  .ant-checkbox-group {
    gap: 12px;
  }
  .ant-checkbox-wrapper {
    height: 35px;
    padding: 7px 12px 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--system-bg, #f5f6f7);
    color: var(--primary-dark-grey, #484c52);
    font-size: 14px;
    font-weight: 700;
    gap: 8px;
    line-height: 12px;
    position: relative;
    .ant-checkbox-inner {
      display: none;
    }
    &.ant-checkbox-wrapper-checked {
      // font-weight: 700;
      color: var(--primary-text, #6d29f6);
      background-color: rgba(109, 41, 246, 0.1);
    }
  }
}
