.requests-detiles-shop {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  // padding: 40px 24px 43px 24px;
  padding: 24px 24px 43px 24px;
  border-radius: 16px;
  gap: 40px;
  margin-top: 40px;
  .total-price {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: start;
    gap: 10px;
    width: 100%;
    .total-price-title {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: #484c52;
    }
    .total-price-text {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: #6d29f6;
      line-height: 31px;
    }
  }
  .shop-card-info {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    width: 100%;
    border: 1px solid #8f9bb34d;
    border-radius: 8px;
    padding: 20px;
    .shop-card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .card-header-title {
        border-bottom: 1px solid #8f9bb34d;
        width: 800px;
        padding-bottom: 10px;
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        color: #484c52;
      }
      .card-header-tag {
        // padding: 6px 30px ;
        width: 127px;
        height: 38px;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .ant-select-arrow .anticon,
        .ant-select-selection-item {
          color: transparent !important;
        }
      }
    }
    .shop-card-data {
      display: flex;
      align-items: start;
      justify-content: start;
      width: 100%;
      gap: 30px;
      padding-top: 25px;
      .shop-card-image {
        width: 180px;
        height: 180px;
        height: 200px;
        flex-basis: 30%;
        padding: 5px;
        background: #f5f6f7;
        border-radius: 8px;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .shop-data-items {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: start;
        gap: 25px;
        flex-basis: 70%;
        .item-description {
          align-items: flex-start !important;
        }
        .shop-data-item {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          .data-item-title {
            margin: 0;
            font-size: 16px;
            font-weight: 700;
            color: #484c52;
          }
          .data-item-text {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: #8f9bb3;
          }
        }
      }
    }
  }
}
.select-tag {
  .ant-select-selector {
    display: flex;
    width: 127px !important;
    height: 38px !important;
    padding: 6px 12px;
    justify-content: space-between;
    align-items: center;
    color: unset !important;
    .ant-select-selection-item {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .ant-select-arrow {
    color: unset !important;
  }
}
@media (max-width: 600px) {
  .shop-card-header {
    width: auto !important;
    justify-content: center !important;
    flex-wrap: wrap;
    gap: 10px;
    .card-header-title {
      width: auto !important;
    }
  }
  .shop-card-data {
    flex-direction: column;
    .shop-card-image {
      width: 100% !important;
      img {
        // width: 100% !important;
      }
    }
  }
  .skeleton-btn-request {
    width: 100% !important;
  }
}
.skeleton-btn-request {
  width: auto !important ;
}
.requests-detiles {
  .customer-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
    .customer-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      color: #002554;
    }
  }
}
