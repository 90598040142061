
@media (max-width: 500px) {
    .Products-edit{
        .btn-edit-save{
            display: none;
        }
    }
    .submit-btn-wrapper{
        justify-content: center;
        .submit-btn-edit{
            font-size: 18px;
            font-weight: 600;
        }
    }
    
}