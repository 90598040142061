.header-product{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    color: #6D29F6;
    .header-title{
        cursor: pointer;
        color: #6D29F6;
        font: {
            size: 30px;
            weight:700;
        };
        line-height: 48px;
    }
    .header-path{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        .text-path{
            cursor: pointer;
            font: {
                size: 20px;
                weight:600;
            };
            line-height: 25px;
        }
    }
}
.sub-title-page{
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 20px 0;
    cursor: pointer;
    p{
        display: flex;
        gap: 5px;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }
}
.text-header-close{
    width: 26px;
    height: 25px;
    flex-shrink: 0;
    color: #484C52;
    cursor: pointer;
}
@media  (max-width: 500px ) {
    .header-product{
        height: auto;
        .header-title{
            font-size: 23px;
        }
        .header-path{
            .text-path{
                font-size: 18px;
            }
        }
    }
}